<template>
  <div>
    <v-footer dark padless>
      <v-card class="flex" flat tile style="background: #4f5353">
        <v-container class="container_principal">
          <v-row class="align-center">
            <v-col cols="12" sm="4" align="center">
              <img src="@/assets/logo_blanco.svg" class="logo_footer" />
              <div class="">
                {{ $t("portada.footer.marca") }}
                <a href="https://www.norvoz.es" target="_blank"
                  >Norvoz Telecom</a
                >
              </div>
            </v-col>
            <v-col cols="12" sm="4" align="center">
              {{ $t("portada.footer.llamanos") }}
              <a href="tel:+34982650065">982 65 00 65</a><br />
              <span>
                {{ $t("portada.footer.o_en") }}
                <a href="mailto:info@fibral.gal">info@fibra.gal</a></span
              >
            </v-col>
            <v-col cols="12" sm="4" align="center">
              <ol class="footer-rrss listaFooter">
                <li class="lineaListadoFooter">
                  <a
                    href="https://twitter.com/fibrapuntogal"
                    title="Twitter"
                    target="_blank"
                  >
                    <svg title="Twitter logo" viewBox="0 0 24 24" height="28">
                      <path
                        fill="#fff"
                        d="M21.999 6.275a8.703 8.703 0 01-2.356.597c.846-.469 1.497-1.212 1.804-2.095a8.632 8.632 0 01-2.606.918C18.093 4.96 17.027 4.5 15.847 4.5c-2.266 0-4.104 1.694-4.104 3.786 0 .297.038.586.106.863-3.41-.158-6.433-1.665-8.456-3.955a3.543 3.543 0 00-.556 1.903c0 1.314.725 2.474 1.826 3.152a4.357 4.357 0 01-1.86-.475v.048c0 1.835 1.414 3.365 3.291 3.713a4.472 4.472 0 01-1.852.066c.521 1.504 2.037 2.6 3.833 2.63-1.404 1.015-3.174 1.62-5.096 1.62A9.01 9.01 0 012 17.799 12.324 12.324 0 008.29 19.5c7.548 0 11.675-5.77 11.675-10.774 0-.163-.004-.327-.012-.49A8.062 8.062 0 0022 6.277l-.001-.001z"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li class="lineaListadoFooter">
                  <a
                    href="https://www.instagram.com/fibrapuntogal/"
                    title="Instagram"
                    target="_blank"
                  >
                    <svg title="Instagram logo" viewBox="0 0 24 24" height="28">
                      <path
                        fill="#fff"
                        d="M11.996 4.621c2.404 0 2.686.011 3.636.054.879.04 1.354.186 1.672.31.421.165.721.358 1.035.672.315.314.511.614.672 1.036.121.318.271.793.31 1.671.043.95.054 1.232.054 3.636s-.01 2.686-.054 3.636c-.039.878-.185 1.353-.31 1.671a2.792 2.792 0 01-.672 1.036 2.75 2.75 0 01-1.035.671c-.318.122-.793.272-1.672.311-.95.043-1.232.054-3.636.054-2.403 0-2.685-.011-3.635-.054-.879-.04-1.354-.186-1.672-.31a2.793 2.793 0 01-1.035-.672 2.749 2.749 0 01-.672-1.036c-.121-.318-.271-.793-.31-1.671-.043-.95-.054-1.232-.054-3.636s.01-2.686.053-3.636c.04-.878.186-1.353.311-1.671.164-.422.357-.722.672-1.036.314-.314.614-.51 1.035-.671.318-.122.793-.272 1.672-.311.95-.046 1.232-.054 3.635-.054zm0-1.621c-2.442 0-2.75.01-3.71.054-.957.042-1.611.196-2.182.417a4.385 4.385 0 00-1.593 1.04c-.5.5-.807 1.003-1.04 1.593-.221.571-.375 1.225-.417 2.185C3.01 9.246 3 9.554 3 11.996c0 2.443.01 2.75.054 3.711.042.957.196 1.61.417 2.186.229.593.54 1.093 1.04 1.593s1.003.807 1.593 1.039c.571.221 1.225.375 2.185.418.961.043 1.265.053 3.711.053 2.446 0 2.75-.01 3.71-.053.958-.043 1.611-.197 2.186-.418a4.385 4.385 0 001.593-1.04c.5-.5.807-1.003 1.04-1.592.221-.572.375-1.225.417-2.186.043-.96.054-1.264.054-3.71 0-2.447-.01-2.75-.054-3.711-.042-.957-.196-1.611-.417-2.186a4.386 4.386 0 00-1.04-1.593c-.5-.5-1.003-.807-1.593-1.04-.571-.22-1.225-.374-2.185-.417-.965-.04-1.272-.05-3.715-.05zm0 4.375a4.622 4.622 0 000 9.243 4.625 4.625 0 004.622-4.622 4.624 4.624 0 00-4.622-4.621zm0 7.621a3 3 0 110-5.999 3 3 0 010 6zm5.883-7.803a1.079 1.079 0 11-2.158 0 1.079 1.079 0 012.158 0z"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li class="lineaListadoFooter">
                  <a
                    href="https://www.facebook.com/fibrapuntogal"
                    title="Facebook"
                    target="_blank"
                  >
                    <svg title="Facebook logo" viewBox="0 0 24 24" height="28">
                      <path
                        fill="#fff"
                        d="M16.5 2.013h-2.804S9.313 1.534 9.313 6.3v1.974H7.529L7.5 12.022h1.812V22h4.155v-9.978h2.402l.583-3.75h-2.985V6.749S13.211 5.46 14.69 5.46h1.78l.028-3.448.002.001z"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li class="lineaListadoFooter">
                  <a
                    href="https://www.linkedin.com/company/fibrapuntogal"
                    title="Linkedin"
                    target="_blank"
                  >
                    <svg
                      class="svgLinkedin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffff"
                      viewBox="0 0 50 50"
                      height="26"
                    >
                      <path
                        d="M 8 3.0117188 C 6.3126093 3.0117188 4.8354789 3.4916328 3.7539062 4.3652344 C 2.6723337 5.238836 2.0117188 6.533218 2.0117188 7.9472656 C 2.0117188 10.690836 4.4687078 12.814467 7.7167969 12.941406 A 0.98809878 0.98809878 0 0 0 8 12.988281 C 9.753566 12.988281 11.246191 12.474267 12.3125 11.564453 C 13.378809 10.654639 13.988281 9.3429353 13.988281 7.9472656 A 0.98809878 0.98809878 0 0 0 13.986328 7.8925781 C 13.832307 5.1316834 11.374781 3.0117187 8 3.0117188 z M 8 4.9882812 C 10.60907 4.9882812 11.895883 6.2693448 12.005859 7.9726562 C 11.998759 8.8049335 11.676559 9.5118991 11.03125 10.0625 C 10.378809 10.619186 9.371434 11.011719 8 11.011719 C 5.3980542 11.011719 3.9882813 9.5991704 3.9882812 7.9472656 C 3.9882812 7.1213132 4.3276663 6.4422421 4.9960938 5.9023438 C 5.6645211 5.3624454 6.6873907 4.9882813 8 4.9882812 z M 3 15 A 1.0001 1.0001 0 0 0 2 16 L 2 45 A 1.0001 1.0001 0 0 0 3 46 L 13 46 A 1.0001 1.0001 0 0 0 14 45 L 14 35.664062 L 14 16 A 1.0001 1.0001 0 0 0 13 15 L 3 15 z M 18 15 A 1.0001 1.0001 0 0 0 17 16 L 17 45 A 1.0001 1.0001 0 0 0 18 46 L 28 46 A 1.0001 1.0001 0 0 0 29 45 L 29 29 L 29 28.75 L 29 28.5 C 29 26.555577 30.555577 25 32.5 25 C 34.444423 25 36 26.555577 36 28.5 L 36 45 A 1.0001 1.0001 0 0 0 37 46 L 47 46 A 1.0001 1.0001 0 0 0 48 45 L 48 28 C 48 23.873476 46.787888 20.604454 44.744141 18.375 C 42.700394 16.145546 39.849212 15 36.787109 15 C 32.882872 15 30.521631 16.426076 29 17.601562 L 29 16 A 1.0001 1.0001 0 0 0 28 15 L 18 15 z M 4 17 L 12 17 L 12 35.664062 L 12 44 L 4 44 L 4 17 z M 19 17 L 27 17 L 27 19.638672 A 1.0001 1.0001 0 0 0 28.744141 20.306641 C 28.744141 20.306641 31.709841 17 36.787109 17 C 39.360007 17 41.615528 17.922268 43.269531 19.726562 C 44.923534 21.530858 46 24.261524 46 28 L 46 44 L 38 44 L 38 28.5 A 1.0001 1.0001 0 0 0 37.916016 28.089844 C 37.694061 25.26411 35.38033 23 32.5 23 C 29.474423 23 27 25.474423 27 28.5 L 27 28.75 L 27 29 L 27 44 L 19 44 L 19 17 z"
                      />
                    </svg>
                  </a>
                </li>
              </ol>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <cookie-law theme="dark-lime" :ref="'cookieBanner'">
        <div slot-scope="props" style="width: 100%">
          <v-row align="center">
            <v-col cols="12" lg="7" offset-lg="1" sm="12">
              <p>
                {{ $t("portada.footer.textoBannerCookies") }}
                <router-link to="politica-cookies">
                  {{ $t("portada.footer.textoBannerCookiesInfo") }}</router-link
                >
              </p>
            </v-col>
            <v-col
              cols="10"
              offset="2"
              offset-lg="0"
              lg="3"
              sm="12"
              offset-sm="4"
            >
              <v-btn @click="props.close()"><span>Rechazar</span></v-btn
              >&nbsp;
              <v-btn
                class="primary"
                @click="
                  props.accept();
                  activarGTM();
                "
                ><div>Aceptar</div>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </cookie-law>
    </v-footer>
    <v-footer dark padless>
      <v-card class="flex" style="background: #363838">
        <v-container>
          <v-row align-selft="center">
            <v-col cols="12" align="center">
              <router-link to="aviso-legal"> Aviso Legal</router-link>&nbsp;
              <router-link to="politica-cookies">
                Política de Cookies</router-link
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
import CookieLaw from 'vue-cookie-law'
export default {
    components: { CookieLaw },
    data () {
        return {
            comprobarCookie: false
        }
    },
    methods: {
        activarGTM () {
            if (
                window.location.hostname === 'fibra.gal' ||
        window.location.hostname === 'www.fibra.gal'
            ) {
                this.$gtm.enable(true)
            }
        }
    },
    mounted () {
        if (
            this.$refs.cookieBanner.isAccepted() &&
      (window.location.hostname === 'fibra.gal' ||
        window.location.hostname === 'www.fibra.gal')
        ) {
            this.$gtm.enable(true)
        } else {
            this.$gtm.enable(false)
        }
    }
}
</script>
